<template>
    <v-card class="my-3">
        <v-card-title
            class="primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
            >Household Income</v-card-title
        >

        <v-card-text>
            <v-row>
                <v-col>
                    <household-monthly-income
                        :summary="
                            $_.get(
                                currentApplication.applicants[0],
                                'order.household_summary'
                            )
                        "
                        hide-actions
                    ></household-monthly-income>
                </v-col>
                <v-col>
                    <household-income-ratio
                        :currentApplication="currentApplication"
                    ></household-income-ratio>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-n2">
                <v-col>
                    <h3 class="my-3">Household Incomes</h3>
                    <household-summary-incomes
                        hide-title
                        :items="householdIncomes"
                    ></household-summary-incomes>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "household-incomes",
    components: {
        HouseholdMonthlyIncome: () =>
            import("@components/screen/components/HouseholdMonthlyIncome"),
        HouseholdIncomeRatio: () =>
            import("@components/screen/components/HouseholdIncomeRatio"),
        HouseholdSummaryIncomes: () =>
            import(
                "@components/screen/components/ScreeningHouseholdSummaryIncome"
            ),
    },
    props: {
        currentApplication: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        householdIncomes() {
            return this.$_.get(
                this.currentApplication.applicants[0],
                "order.household_summary.incomes",
                []
            ).filter((item) => item.verified);
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>