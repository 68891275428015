var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-3" },
    [
      _c(
        "v-card-title",
        {
          staticClass:
            "primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
        },
        [_vm._v("Household Income")]
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("household-monthly-income", {
                    attrs: {
                      summary: _vm.$_.get(
                        _vm.currentApplication.applicants[0],
                        "order.household_summary"
                      ),
                      "hide-actions": ""
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("household-income-ratio", {
                    attrs: { currentApplication: _vm.currentApplication }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-n2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("h3", { staticClass: "my-3" }, [
                    _vm._v("Household Incomes")
                  ]),
                  _c("household-summary-incomes", {
                    attrs: { "hide-title": "", items: _vm.householdIncomes }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }